<script lang="ts" setup>
import LavenderIcon from '@/Components/UI/LavenderIcon.vue'
import { LetterResource } from '@/Types/generated'
import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

const page = usePage()

const letter = computed<LetterResource>(() => page.props.letter)
</script>

<template>
  <div
    id="story"
    class="w-full mt-8 scroll-mt-20"
  >
    <div class="flex justify-center">
      <div class="max-w-5xl">
        <div class="flex items-center justify-center flex-col text-center">
          <h2 class="text-4xl font-bold block font-serif tracking-wide text-gray-700">
            To {{ letter.to }}
          </h2>
          <div class="flex items-center flex-row justify-center">
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
            <div class="flex items-center">
              <LavenderIcon />
            </div>
            <div class="text-gray-400 px-2">
              &#8212;&#8212;
            </div>
          </div>
        </div>

        <div class="text-center space-y-8 px-4 sm:px-0">
          <p
            class="leading-6 text-gray-500 font-sans"
            v-html="letter.content"
          />
          <p class="mt-8 text-gray-500 font-semibold">
            Thank you,<br>
            Mr + Mrs Hargrave
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
