export enum ActivityAttendanceEnum { 'Attending' = 'attending', 'NotAttending' = 'not_attending', 'NotResponded' = 'not_responded', 'NotLoggedIn' = 'not_logged_in', 'NotInvited' = 'not_invited' };
export type ActivityCanResource = {
update: boolean;
delete: boolean;
update_attendance: boolean;
};
export type ActivityFormResource = {
id: string;
name: string;
is_public: boolean;
guests: Array<SelectResource>;
description: string;
booking_details: string | null;
price: string | null;
image: Array<any> | null;
starts_at: string | null;
ends_at: string | null;
};
export type ActivityGuestResource = {
id: string;
name: string;
avatar: string | null;
attendance: ActivityAttendanceEnum;
};
export type ActivityResource = {
id: string;
name: string;
description: string;
booking_details: string | null;
price: string | null;
image: string | null;
thumbnail: string | null;
starts_at: string | null;
ends_at: string | null;
is_public: boolean;
attendance?: ActivityAttendanceEnum;
comments_count?: number;
guests?: Array<ActivityGuestResource>;
calendar_links?: CalendarLinksResource | null;
can?: ActivityCanResource;
};
export enum BoardGameLibraryEnum { 'His' = 'his', 'Hers' = 'hers' };
export type BoardGameResource = {
id: string;
bgg_id: number;
library: BoardGameLibraryEnum;
name: string;
description: string;
image: string | null;
min_players: number;
max_players: number;
playing_time: number;
min_playing_time: number;
max_playing_time: number;
min_age: number;
categories: Array<string>;
mechanics: Array<string>;
};
export type CalendarLinksResource = {
google: string;
outlook: string;
yahoo: string;
web_office: string;
ics: string;
};
export type CommentCanResource = {
delete: boolean;
update: boolean;
};
export type CommentReactionSummaryResource = {
reaction: string;
count: number;
commentator_reacted: boolean;
reacted_by: Array<ReactedByGuestResource>;
};
export type CommentResource = {
id: string;
text: string;
created_at: string;
comments_count?: number;
user?: UserResource | null;
can?: CommentCanResource;
reactions?: Array<CommentReactionSummaryResource>;
is_deleted: boolean;
};
export enum Commentable { 'Media' = 'media', 'Activity' = 'activity', 'Comment' = 'comment' };
export type CommentsConfigDTO = {
allowed_reactions: Array<string>;
};
export type ConfigDTO = {
comments: CommentsConfigDTO;
};
export type ContentSettings = {
our_story_content: string;
newsletter_content: string;
gift_content: string;
minecraft_content: string;
excursion_content: string;
};
export type CourseOptionResource = {
id: string;
name: string;
description: string | null;
is_vegetarian: boolean;
is_vegan: boolean;
};
export type CourseResource = {
id: string;
name: string;
description: string | null;
options?: Array<CourseOptionResource>;
};
export enum EventAttendanceEnum { 'NoRsVp' = 'no_rsvp', 'Attending' = 'attending', 'NotAttending' = 'not_attending', 'NotResponded' = 'not_responded', 'NotInvited' = 'not_invited', 'NotLoggedIn' = 'not_logged_in' };
export type EventResource = {
id: string;
name: string;
details: string;
slug: string;
image: string | null;
attendance?: EventAttendanceEnum;
has_food: boolean;
has_rsvp: boolean;
menus?: Array<MenuResource> | null;
table?: TableResource | null;
is_public: boolean;
starts_at: string | null;
ends_at: string | null;
rsvp_by: string | null;
calendar_links: CalendarLinksResource | null;
};
export type ExcursionResource = {
id: string;
name: string;
description: string;
price: string | null;
image: string | null;
thumbnail: string | null;
image_media?: string | null;
booking_details: string | null;
is_public: boolean;
suggested_by?: SelectResource | null;
};
export type FeatureSettings = {
gallery_enabled: boolean;
checklist_enabled: boolean;
events_enabled: boolean;
guests_enabled: boolean;
newsletter_enabled: boolean;
download_all_media_enabled: boolean;
letters_enabled: boolean;
board_games_enabled: boolean;
activities_enabled: boolean;
excursions_enabled: boolean;
minecraft_enabled: boolean;
};
export type GuestResource = {
id: string;
name: string;
bio?: string | null;
dietary_restrictions?: string | null;
avatar: string;
avatar_media?: Array<any> | null;
user?: UserResource;
};
export type InviteResource = {
id: string;
name: string | null;
email: string | null;
};
export type LetterResource = {
content: string;
to: string;
};
export type MediaCanResource = {
delete: boolean;
update: boolean;
};
export type MediaResource = {
id: string;
url: string | null;
thumbnail_url: string | null;
mime_type: string;
uuid: string;
is_public: boolean;
is_background: boolean;
is_featured: boolean;
comments_count?: number;
taken_at: string | null;
created_at: string | null;
user?: UserResource | null;
photographer?: PhotographerResource | null;
can?: MediaCanResource | null;
};
export type MenuResource = {
id: string;
name: string;
description: string | null;
courses?: Array<CourseResource>;
};
export enum NotificationEnum { 'CommentReceived' = 'comment_received', 'NewInvite' = 'new_invite', 'NewPublicEvent' = 'new_public_event', 'EventUpdated' = 'event_updated', 'AfterEvent' = 'after_event', 'RsvpDeadline' = 'rsvp_deadline', 'MentionedInComment' = 'mentioned_in_comment', 'ReactionToComment' = 'reaction_to_comment', 'CommentReplies' = 'comment_replies', 'NewPublicActivity' = 'new_public_activity', 'InvitedToActivity' = 'invited_to_activity', 'ActivityUpdated' = 'activity_updated', 'ActivityCanceled' = 'activity_canceled', 'ActivityReminder' = 'activity_reminder', 'AfterActivity' = 'after_activity', 'UserAttendingActivity' = 'user_attending_activity', 'UserNotAttendingActivity' = 'user_not_attending_activity' };
export type NotificationResource = {
id: string | null;
message: string | null;
url: string | null;
read_at: string | null;
created_at: string;
};
export type NotificationTypeResource = {
notification: NotificationEnum;
label: string;
};
export type PhotographerResource = {
id: string;
name: string;
url: string | null;
avatar: string;
};
export type ReactedByGuestResource = {
id: string;
name: string;
avatar: string | null;
};
export type SelectResource = {
id: string;
name: string;
avatar: string | null;
is_removable: boolean;
};
export type TableResource = {
id: string;
name: string;
};
export type TodoResource = {
id: string;
name: string;
details: string | null;
is_locked: boolean;
is_completed: boolean;
};
export type UserNotificationResource = {
count: number | null;
unread_count: number | null;
};
export type UserResource = {
id: string;
name: string;
email?: string;
has_newsletter?: boolean;
enabled_notifications?: Array<string> | null;
guest?: GuestResource | null;
is_admin?: boolean;
notifications?: UserNotificationResource;
};
